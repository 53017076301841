import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { query, collection, getDocs, where, orderBy } from "firebase/firestore"
import { db } from '../firebase_config.js';
import '../App.css'

import Arrowdown from '../images/renewal/Arrowdown_white.png';
import Subtract from '../images/renewal/Subtract.svg';
// import Back from '../images/renewal/Back.svg';
import ArrowBack from '../images/renewal/Arrow-down.svg';
import LogoMobile from '../images/renewal/MobileLogo.svg';

function DrawPage(props) {
    const { state } = useLocation();    //전 페이지에서 넘긴 매개변수 저장하는 변수
    const navigate = useNavigate();
    const [fileinfo, setFileinfo] = useState(state.fileinfo);
    const fromPage = state.from;

    const canvasRef = useRef(null);
    const [getCtx, setGetCtx] = useState(null);
    const [painting, setPainting] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [isWidthGreaterThanHeight, setIsWidthGreaterThanHeight] = useState(window.innerWidth > window.innerHeight);
    
    const [isStart, setIsStart] = useState(false);  //초기화할때 상태값 체크 변수
    const [color, setColor] = useState('black');    //펜 색깔
    const [penWidth, setPenWidth] = useState(4);    //펜 굵기

    // const [cateList, setCateList] = useState([]);
    const [contentList,setContentList] = useState([]);
    // const [selCategory, setSelCategory] = useState(fileinfo.cateval);
    const [selTag, setSelTag] = useState(fileinfo.tag);
    // const [isCateOpen, setIsCateOpen] = useState(false);
    const [isTagOpen, setIsTagOpen] = useState(false);
    // const cateDropdownRef = useRef(null);
    // const cateButtonRef = useRef(null);
    const tagDropdownRef = useRef(null);
    const tagButtonRef = useRef(null);

    const [selectedStroke, setSelectedStroke] = useState('Stroke2'); //굵기 선택
    const [selectedColor, setSelectedColor] = useState('Color1'); //색깔 선택

    // useEffect(() => {
    //     const fetchData = async () => {
    //         const q = query(collection(db, "category"), where("sysdiv", "==", props.sysDiv), 
    //                             orderBy("value"));
    //         const snapshot = await getDocs(q);
        
    //         const data = snapshot.docs.map(doc => ({
    //             ...doc.data(),
    //             id: doc.id,
    //         }));
            
    //         setCateList(data);
    //     }
    
    //     fetchData(); // fetchData 함수를 즉시 호출
    // }, [props])

    useEffect(() => {
        if(fromPage !== "patientcontent"){
            const fetchData = async () => {
                const q = query(collection(db, "contents"), where("sysdiv", "==", props.sysDiv), 
                                where("repcateval","==", fileinfo.repcateval),
                                orderBy("uploadtime","desc"));
                const snapshot = await getDocs(q);
            
                const data = snapshot.docs.map(doc => ({
                    ...doc.data(),
                    id: doc.id,
                }));
                setContentList(data);
            }
        
            fetchData(); // fetchData 함수를 즉시 호출
        }
    }, [props, fileinfo.repcateval, fromPage])

    useEffect(() => {
        function handleClickOutside(event) {
            // if (cateDropdownRef.current && !cateDropdownRef.current.contains(event.target)&&
            //     cateButtonRef.current && !cateButtonRef.current.contains(event.target)) {
            //     setSelCategory(fileinfo.cateval);
            //     setIsCateOpen(false);
            // }
            if (tagDropdownRef.current && !tagDropdownRef.current.contains(event.target)&&
                tagButtonRef.current && !tagButtonRef.current.contains(event.target)) {
                // setSelCategory(fileinfo.cateval);
                setSelTag(fileinfo.tag);
                setIsTagOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [fileinfo.cateval, fileinfo.tag]);

    useEffect(() => {
        function handleResize() {
          setIsWidthGreaterThanHeight(window.innerWidth > window.innerHeight);
        }
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const canvas = canvasRef.current;
        const image = new Image();
        image.src = fileinfo.fileAddr;

        // 현재 root 요소의 font-size를 가져옵니다.
        const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);

        if(window.innerWidth > 1400){
            // canvas.width = window.innerWidth-520;
            // canvas.height = window.innerHeight-250;
            canvas.width = 87.5 * rootFontSize;
            canvas.height = window.innerHeight - (15 * rootFontSize);
        }else if(!isWidthGreaterThanHeight){
            // canvas.width = window.innerWidth-80;
            // canvas.height = 565;
            canvas.width = 59.0625 * rootFontSize;
            canvas.height = 35.3125 * rootFontSize;
        }else {
            // 태블릿 가로로 세웠을때 처럼 가로길이가 세로길이보다 길때 처리
            canvas.width = 67.5 * rootFontSize;
            canvas.height = window.innerHeight - (15 * rootFontSize);
        }
        
        const ctx = canvas.getContext("2d");
        ctx.lineJoin = "round";
        //ctx.lineWidth = 2.5;
        //ctx.strokeStyle = "black";
        setGetCtx(ctx);

        image.onload = function() {
            const imageAspectRatio = image.width / image.height;
            const canvasAspectRatio = canvas.width / canvas.height;
            
            let drawWidth, drawHeight, drawX, drawY;

            if (imageAspectRatio > canvasAspectRatio) {
                // 이미지가 캔버스보다 가로로 더 긴 경우
                drawWidth = canvas.width;
                drawHeight = canvas.width / imageAspectRatio;
                drawX = 0;
                drawY = (canvas.height - drawHeight) / 2;
            } else {
                // 이미지가 캔버스보다 세로로 더 긴 경우
                drawHeight = canvas.height;
                drawWidth = canvas.height * imageAspectRatio;
                drawX = (canvas.width - drawWidth) / 2;
                drawY = 0;
            }
            ctx.drawImage(image, drawX, drawY, drawWidth, drawHeight);
            // ctx.drawImage(image,0,0,canvas.width,canvas.height);
        }
    },[fileinfo, isStart, isWidthGreaterThanHeight])

    const drawFn = e => {
        if (!getCtx) return;  // getCtx가 null이면 함수 실행을 중단
        
        setIsStart(true);
        //mouse position
        const mouseX = e.nativeEvent.offsetX;
        const mouseY = e.nativeEvent.offsetY;
        //drawing
        if (!painting) {
            getCtx.beginPath();
            getCtx.moveTo(mouseX, mouseY);
        } else {
            getCtx.lineTo(mouseX, mouseY);
            getCtx.strokeStyle = color;
            getCtx.lineWidth = penWidth;
            getCtx.stroke();
        }
    }

    const touchStart = e => {
        getCtx.beginPath();
        setIsActive(true);
    }

    const touch = e => {
        setIsStart(true);
        var touch = e.touches[0];
        const mouseX = touch.clientX - e.target.offsetLeft;
        //const mouseY = touch.clientY - e.target.offsetTop - document.documentElement.scrollTop;
        const mouseY = touch.clientY - e.target.offsetTop;
        
        if(!isActive) {
            getCtx.beginPath();
            getCtx.moveTo(mouseX, mouseY);
        } else {
            getCtx.lineTo(mouseX, mouseY);
            getCtx.strokeStyle = color;
            getCtx.lineWidth = penWidth;
            getCtx.stroke();
        }
    }

    const touchEnd = e => {
        if(!isActive) {
            return;
        } else {
            getCtx.beginPath();
        }
        
        setIsActive(false);
        return;
    }

    //초기화
    const handleReset = () => {
        getCtx.clearRect(0,0,canvasRef.current.width,canvasRef.current.height)
        setIsStart(false)
    }

    // const toggleCateDropdown = () => {
    //     setIsCateOpen(!isCateOpen);
    // };

    const toggleTagDropdown = () => {
        setIsTagOpen(!isTagOpen);
    };

    const handleStrokeClick = async (stroke) => {
        setSelectedStroke(stroke);
        if(stroke === 'Stroke1'){
            setPenWidth(2);
        }else if(stroke === 'Stroke2'){
            setPenWidth(4);
        }else if(stroke === 'Stroke3'){
            setPenWidth(8);
        }
    };

    const handleColorClick = async (color) => {
        setSelectedColor(color);
        if(color === 'Color1'){
            setColor('#000');
        }else if(color === 'Color2'){
            setColor('#252525');
        }else if(color === 'Color3'){
            setColor('#E53122');
        }else if(color === 'Color4'){
            setColor('#0B07FA');
        }else if(color === 'Color5'){
            setColor('#3F7E15');
        }else if(color === 'Color6'){
            setColor('#ACEC95');
        }else if(color === 'Color7'){
            setColor('#FEFF41');
        }
    };

    const toDentalExplanPage = () => {
        navigate("/", {
            state: {
                cateval: fileinfo.cateval,
                repcateval: fileinfo.repcateval,
                sysdiv: "DC"
            }
        });
    };

    return (
        <div className="renew_background drawvideo" >
            <div className="drawpage_content_area">
                <div className="header_group">
                    <div style={{display:'flex'}}>
                        <div className="renew_video_back"
                                onClick={()=>{
                                    if(fromPage !== "patientcontent"){
                                        toDentalExplanPage()
                                    }else{
                                        navigate(-1)
                                    }
                                }}>
                            <img src={ArrowBack} alt='뒤로' style={{width:"1.5rem",height:"1.5rem"}} />
                        </div>
                        {fromPage !== "patientcontent" &&
                            <div style={{position:'relative'}}>
                                <div ref={tagButtonRef}
                                    className="renew_content_sel"
                                    onClick={toggleTagDropdown}
                                >
                                    <div>{selTag}</div>
                                    <img src={Arrowdown} alt='선택' style={{width:"1.5rem",height:"1.5rem"}}></img>
                                </div>
                                {isTagOpen && (
                                    <div className="renew_dropdown_menu" ref={tagDropdownRef}>
                                        {contentList
                                        .map(content => (
                                            <div key={content.id} 
                                                style={{marginBottom:"1.64rem",cursor:'pointer',
                                                        color:selTag === content.tag ? "#FFF" : "#A9A9A9"}}
                                                onClick={()=>{
                                                    if(content.filetype === "video"){
                                                        navigate("/viewVideo", {
                                                            state: {
                                                                fileinfo: content,
                                                            },
                                                        });
                                                    }else{
                                                        setFileinfo(content);
                                                        setSelTag(content.tag);
                                                        toggleTagDropdown();
                                                    }
                                                }}
                                            >{content.tag}</div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        }
                    </div>
                    <div className="draw_tool_desktop">
                        <div className="renew_back_del" style={{border:'1px solid #2D2D2D'}}>
                            <img src={Subtract} alt='그리기' style={{width:"1.5rem",height:"1.5rem"}}/>
                        </div>
                        <div style={{display:'flex',alignItems:'flex-start',gap:"0.625rem"}}>
                            <div className="renew_stroke_sel"
                                style={{ border:selectedStroke === 'Stroke1' && '1px solid #949494'}}
                                onClick={() => handleStrokeClick('Stroke1')}
                            >가늘게</div>
                            <div className="renew_stroke_sel"
                                style={{ border:selectedStroke === 'Stroke2' && '1px solid #949494'}}
                                onClick={() => handleStrokeClick('Stroke2')}
                            >보통</div>
                            <div className="renew_stroke_sel"
                                style={{ border:selectedStroke === 'Stroke3' && '1px solid #949494'}}
                                onClick={() => handleStrokeClick('Stroke3')}
                            >굵게</div>
                        </div>
                        <div style={{display:'flex',alignItems:'flex-start',gap:"0.625rem"}}>
                            <div className="renew_color_unit" onClick={() => handleColorClick('Color1')}
                                style={{background:'#000',border:selectedColor === 'Color1' && '1px solid #949494'}}
                            />
                            <div className="renew_color_unit" onClick={() => handleColorClick('Color2')}
                                style={{background:'#252525',border:selectedColor === 'Color2' && '1px solid #949494'}}
                            />
                            <div className="renew_color_unit" onClick={() => handleColorClick('Color3')}
                                style={{background:'#E53122',border:selectedColor === 'Color3' && '1px solid #949494'}}
                            />
                            <div className="renew_color_unit" onClick={() => handleColorClick('Color4')}
                                style={{background:'#0B07FA',border:selectedColor === 'Color4' && '1px solid #949494'}}
                            />
                            <div className="renew_color_unit" onClick={() => handleColorClick('Color5')}
                                style={{background:'#3F7E15',border:selectedColor === 'Color5' && '1px solid #949494'}}
                            />
                            <div className="renew_color_unit" onClick={() => handleColorClick('Color6')}
                                style={{background:'#ACEC95',border:selectedColor === 'Color6' && '1px solid #949494'}}
                            />
                            <div className="renew_color_unit" onClick={() => handleColorClick('Color7')}
                                style={{background:'#FEFF41',border:selectedColor === 'Color7' && '1px solid #949494'}}
                            />
                        </div>
                        {/* <div className="renew_back_del" style={{cursor:'pointer'}} onClick={()=>toDentalExplanPage()}>
                            <img src={Back} alt='이전으로' style={{width:20,height:20}} />
                        </div> */}
                        <div className="renew_back_del" style={{padding:"0.5625rem 0.375rem",color:'#FFF',fontSize:"0.875rem",
                            fontWeight:500,cursor:'pointer'}} onClick={handleReset}
                        >필기삭제
                        </div>
                    </div>
                </div>
                <div style={{display:'flex',justifyContent:'center',alignItems:'center',touchAction:'none'}}>
                    <canvas
                        ref={canvasRef}
                        onMouseDown={() => setPainting(true)}
                        onMouseUp={() => setPainting(false)}
                        onMouseMove={e => drawFn(e)}
                        onMouseLeave={() => setPainting(false)}
                        onTouchStart={e => touchStart(e)}
                        onTouchMove={e => touch(e)}
                        onTouchEnd={e => touchEnd(e)}
                        style={{borderRadius:20}}
                    >
                    </canvas>
                </div>
                <div className="draw_tool_tablet">
                    <div style={{display:'inline-flex',padding:"0.375rem",alignItems:'center',gap:"0.625rem",
                        borderRadius:"0.5rem",background:'#2D2D2D'}}>
                        <div className="renew_back_del" style={{border:'1px solid #2D2D2D'}}>
                            <img src={Subtract} alt='그리기' style={{width:"1.5rem",height:"1.5rem"}}/>
                        </div>
                        <div style={{display:'flex',alignItems:'flex-start',gap:"0.625rem"}}>
                            <div className="renew_stroke_sel"
                                    style={{ border:selectedStroke === 'Stroke1' && '1px solid #949494'}}
                                    onClick={() => handleStrokeClick('Stroke1')}
                            >가늘게</div>
                            <div className="renew_stroke_sel"
                                    style={{ border:selectedStroke === 'Stroke2' && '1px solid #949494'}}
                                    onClick={() => handleStrokeClick('Stroke2')}
                            >보통</div>
                            <div className="renew_stroke_sel"
                                    style={{ border:selectedStroke === 'Stroke3' && '1px solid #949494'}}
                                    onClick={() => handleStrokeClick('Stroke3')}
                            >굵게</div>
                        </div>
                        <div style={{display:'flex',alignItems:'flex-start',gap:"0.625rem"}}>
                            <div className="renew_color_unit" onClick={() => handleColorClick('Color1')}
                                    style={{background:'#000',border:selectedColor === 'Color1' && '1px solid #949494'}}
                            />
                            <div className="renew_color_unit" onClick={() => handleColorClick('Color2')}
                                    style={{background:'#252525',border:selectedColor === 'Color2' && '1px solid #949494'}}
                            />
                            <div className="renew_color_unit" onClick={() => handleColorClick('Color3')}
                                    style={{background:'#E53122',border:selectedColor === 'Color3' && '1px solid #949494'}}
                            />
                            <div className="renew_color_unit" onClick={() => handleColorClick('Color4')}
                                    style={{background:'#0B07FA',border:selectedColor === 'Color4' && '1px solid #949494'}}
                            />
                            <div className="renew_color_unit" onClick={() => handleColorClick('Color5')}
                                    style={{background:'#3F7E15',border:selectedColor === 'Color5' && '1px solid #949494'}}
                            />
                            <div className="renew_color_unit" onClick={() => handleColorClick('Color6')}
                                    style={{background:'#ACEC95',border:selectedColor === 'Color6' && '1px solid #949494'}}
                            />
                            <div className="renew_color_unit" onClick={() => handleColorClick('Color7')}
                                    style={{background:'#FEFF41',border:selectedColor === 'Color7' && '1px solid #949494'}}
                            />
                        </div>
                        {/* <div className="renew_back_del" style={{cursor:'pointer'}} onClick={()=>toDentalExplanPage()}>
                            <img src={Back} alt='이전으로' style={{width:20,height:20}} />
                        </div> */}
                        <div className="renew_back_del" style={{padding:"0.5625rem 0.375rem",color:'#FFF',fontSize:"0.875rem",
                            fontWeight:500,cursor:'pointer'}} onClick={handleReset}
                        >필기삭제
                        </div>
                    </div>
                </div>
            </div>
            <div className="drawpage_content_area_mobile" style={{width:window.innerWidth,height:window.innerHeight}}>
                <img src={LogoMobile} alt="모바일로고" style={{width:"5rem",height:"6.125",flexShrink:0,marginBottom:"1.56rem"}} />
                <div>더 나은 서비스를 위해</div>
                <div>큰 화면으로 이용해주세요.</div>
            </div>
        </div>
    )
}

export default DrawPage